import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import auth from '../services/auth'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    meta: { requiresAuth: true },
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'map',
        path: 'map',
        component: () => import('../pages/maps/MapPage.vue'),
        props: (route) => ({
          lat: route.query.lat,
          lng: route.query.lng,
        }),
      },
      {
        name: 'crud-users',
        path: 'crud-users',
        component: () => import('../pages/crud-users/UsersPage.vue'),
      },
      {
        name: 'crud-gas-stations',
        path: 'crud-gas-stations',
        component: () => import('../pages/crud-gas-stations/GasStationsPage.vue'),
      },
      {
        name: 'crud-companies',
        path: 'crud-companies',
        component: () => import('../pages/crud-companies/CompaniesPage.vue'),
      },
      // {
      //   name: 'crud-endusers',
      //   path: 'crud-endusers',
      //   component: () => import('../pages/crud-endusers/EndusersPage.vue'),
      // },
      {
        name: 'crud-pumps',
        path: 'crud-pumps',
        component: () => import('../pages/crud-pumps/PumpsPage.vue'),
      },
      {
        name: 'crud-fuel-types',
        path: 'crud-fuel-types',
        component: () => import('../pages/crud-fuel-types/FuelTypesPage.vue'),
      },
      {
        name: 'crud-vehicle-plates',
        path: 'crud-vehicle-plates',
        component: () => import('../pages/crud-vehicle-plates/VehiclePlatesPage.vue'),
      },
      {
        name: 'crud-stations-for-plates',
        path: 'crud-stations-for-plates',
        component: () => import('../pages/crud-stations-for-plates/StationsForPlatesPage.vue'),
      },
      {
        name: 'companies-view',
        path: 'companies-view',
        component: () => import('../pages/views/CompaniesFull/CompaniesViewPage.vue'),
      },
      {
        name: 'plates-view',
        path: 'plates-view',
        component: () => import('../pages/views/PlatesFull/PlatesViewPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    meta: { requiresAuth: false },
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    path: '/webapp',
    name: 'WebApp',
    component: () => import('../pages/web-app/WebAppPage.vue'),
  },
  {
    path: '/webapp/user-details',
    name: 'UserDetails',
    component: () => import('../pages/web-app/user-details/UserDetailsPage.vue'),
  },
  {
    path: '/webapp/qr-code',
    name: 'QrCode',
    component: () => import('../pages/web-app/qrScanPage.vue'),
  },
  {
    path: '/webapp/transactions',
    name: 'Transactions',
    component: () => import('../pages/web-app/transactions/TransactionsPage.vue'),
  },
  {
    path: '/webapp/after-scan',
    name: 'AfterScan',
    component: () => import('../pages/web-app/AfterScanPage.vue'),
  },
  {
    path: '/webapp/fueling-progress',
    name: 'FuelingProgress',
    component: () => import('../pages/web-app/FuelingProgressPage.vue'),
  },
  {
    path: '/webapp/enter-amount',
    name: 'EnterAmount',
    component: () => import('../pages/web-app/EnterAmountPage.vue'),
  },
  {
    path: '/webapp/transaction-single',
    name: 'TransactionSingle',
    component: () => import('../pages/web-app/Transactions/SingleTransactionPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !auth.isAuthenticated()) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router
