import instance from './api/api';

export default {
  async login(email: string, password: string) {
    try {
      const response = await instance.post('/login', { email, password });
      const { access_token, refresh_token } = response.data;

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  },

  getToken() {
    return localStorage.getItem('access_token');
  },

  isAuthenticated() {
    const token = this.getToken();
    return !!token;
  },
};
